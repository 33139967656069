import { createStyles, makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

const loginStyle = makeStyles((theme) => createStyles({
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? '100vw' : 550,
    height: 550,
    marginRight: isMobile ? undefined : 30,
    padding: 20,
    backgroundColor: theme.palette?.type === 'dark' ? '#111a' : '#fffa'
  }   
}));
export default loginStyle;
