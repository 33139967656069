import * as React from "react";
import { graphql, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "../components/index.module.css"

import Layout from "../components/layout";
import PageError from "../components/PageError";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { 
  Button, Paper, TextField,
  Slide, Typography 
} from '@material-ui/core';

import { connect } from 'react-redux';
import Api from '../lib/api';
import Helper from '../lib/helper';
import jwt_decode from "jwt-decode";
import qs from 'qs';

import useStyles from '../assets/style/loginStyle';
import * as loggingActions from '../redux/actions/loggingActions'

const PwdResetPage = (props) => {
  const {t} = useTranslation('pwdreset');
  const classes = useStyles();
  const locale = props.ui.get('lang');
  const setting = Helper.getStateAsJSObj(props.system, 'setting', null);
  const appDetail = Helper.carefullyGetValue(setting, 'data.application'.split('.'), null);
  const sysConfig = Helper.getStateAsJSObj(props.system, 'setting.data.configs', []);
  const logConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.logging');
  const logEnabled = Helper.carefullyGetValue(logConfig, 'value.log_enable'.split('.'), false);
  const consoleLogEnabled = Helper.carefullyGetValue(logConfig, 'value.console_log_enable'.split('.'), false);

  const [pwdResetStatus, setPwdResetStatus] = React.useState(0); // 0=Not yet reset, 1=Resetting in progress, 2=Reset completed 
  const [pwdError, setPwdError] = React.useState(''); 
  
  const pwdResetFormRef = React.useRef(null);
  const [pwdResetValidation, setPwdResetValidation] = React.useState({
    newPassword: {error: false, touched: false, message: ''},
    confirmPassword: {error: false, touched: false, message: ''}
  });
  let passwordPolicy = Helper.carefullyGetValue(appDetail, ['passwordPolicy'], null);
  let regex = Helper.carefullyGetValue(passwordPolicy, ['regex'], '');
  const pwdResetFields = [
    {field: 'newPassword', required: true, regExp: regex, type: 'string', label: 'field.newpassword'},
    {field: 'confirmPassword', required: true, regExp: regex, type: 'string', label: 'field.confirmpassword'}
  ];

  let policyLabelText ='';
  let policyLabel = Helper.carefullyGetValue(passwordPolicy, ['label'], []);
  if(Helper.arrayHasItem(policyLabel)) {
    let policyLabelLang = policyLabel.find(item => item.locale === locale);
    if(policyLabelLang) {
      policyLabelText = `${t('label.passwordplicy.note')}${policyLabelLang.text}`;
    }
  }

  let queryParam = null;
  if(Helper.stringHasValue(props.location?.search))
    queryParam = qs.parse(props.location.search.substr(1));

  let decoded = null;
  let tokenExpired = false;
  let invalidToken = Helper.carefullyGetValue(queryParam, ['token'], '').length <= 0;
  if(!invalidToken) {
    // If have token, make sure the token is still valid.
    try {
      decoded = jwt_decode(queryParam.token);
      tokenExpired = new Date().getTime() >= (decoded.exp * 1000); // convert exp into milliseconds  
    } catch (error) {
      invalidToken = true;
    }
  }

  const passwordReset = () => {
    setPwdError('');
    setPwdResetStatus(0);
    let newPassword = Helper.carefullyGetValue(pwdResetFormRef, 'current.elements.newPassword.value'.split('.'), '');
    let confirmPassword = Helper.carefullyGetValue(pwdResetFormRef, 'current.elements.confirmPassword.value'.split('.'), '');

    let valid = Helper.validateFormRef(
      pwdResetFormRef, pwdResetFields, pwdResetValidation, 
      setPwdResetValidation, t,
      _ => {
        let isValid = true;

        if(newPassword !== confirmPassword) {
          isValid = false;
          setPwdError(t('error.pwdNotMatch.message'));
        }

        return isValid;
      });
    
    if(valid) {
      setPwdResetStatus(1);
      Api.passwordReset(queryParam.token, newPassword)
      .then(_ => {
        setPwdResetStatus(2);
      })
      .catch(err => {
        setPwdResetStatus(0);
        setPwdError(err.message);

        if(logEnabled) {
          err.message += ' - Api.passwordReset Error @ /pwdreset.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(err, {
                session: props.session.toJS()
              })
            )
          );
        }
  
        consoleLogEnabled && console.error('Api.passwordReset Error @ /pwdreset.js: ', err);
      });
    }
  }

  return (
    <Layout showMenu={false} busy={pwdResetStatus === 1} backgroundImage={'/login_bg.jpg'} 
      childrenStyle={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {
        (invalidToken || tokenExpired) &&
        <PageError 
          style={{
            minHeight: 150,
            maxWidth: 500
          }}
          title={t(`error.${invalidToken ? 'invalidToken' : 'tokenExpired'}.title`)} 
          message={t(`error.${invalidToken ? 'invalidToken' : 'tokenExpired'}.message`)} 
          actionStyle={{
            justifyContent: 'center'
          }}
          actionControl={
            <Button variant='contained' color='primary' onClick={_ => {navigate('/')}}>
              {t('button.login')}
            </Button>
          }
        />
      }
      {
        !invalidToken && !tokenExpired &&
        <Slide direction={'up'} in={true} mountOnEnter unmountOnExit>
          <Paper variant={'elevation'} elevation={4} className={classes.loginBox}>
            <div className={styles.textCenter}>
              <StaticImage
                src="../images/app_logo.png"
                loading="eager"
                width={300}
                quality={95}
                formats={["auto"]}
                alt=""
                style={{ marginBottom: `var(--space-3)` }}
              />
              <h1>
                {t(pwdResetStatus === 2 ? 'label.pwdresetsuccess.title' : 'prompt.resetpassword')}
              </h1>
            </div>
            {
              pwdResetStatus !== 2 &&
              <form ref={pwdResetFormRef} onSubmit={(event) => {
                  passwordReset();
                  event.preventDefault();
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography variant='subtitle1' color="secondary" style={{marginBottom: 30}}>
                    {policyLabelText}
                </Typography>
                {/* Create a hidden field for username for browser password manager and to prevent DOM warning. */}
                <input id="username" 
                  autoComplete='username'
                  value={decoded?.email} 
                  disabled={true}
                  hidden={true}
                />
                <TextField id="newPassword" label={t('field.newpassword')} 
                  style={{marginBottom: 10}}
                  variant="outlined" 
                  autoComplete='new-password'
                  type={'password'}
                  error={pwdResetValidation.newPassword.error}
                  helperText={pwdResetValidation.newPassword.message}
                  onBlur={(event) => Helper.validateFormField('newPassword', event.target.value, pwdResetFields, pwdResetValidation, setPwdResetValidation, t)}
                />
                <TextField id="confirmPassword" label={t('field.confirmpassword')} 
                  style={{marginBottom: 10}}
                  variant="outlined" 
                  autoComplete='new-password'
                  type={'password'}
                  error={pwdResetValidation.confirmPassword.error}
                  helperText={pwdResetValidation.confirmPassword.message}
                  onBlur={(event) => Helper.validateFormField('confirmPassword', event.target.value, pwdResetFields, pwdResetValidation, setPwdResetValidation, t)}
                />
                {
                  Helper.stringHasValue(pwdError) &&
                  <Typography variant='subtitle1' color="error" className={classes.title}>
                    {pwdError}
                  </Typography>
                }
                <Button variant={'contained'} type='submit' 
                  color={'primary'} style={{marginBottom: 10, minWidth: 120}}
                  // If passwordPolicy is not defined, do not allow user to reset password
                  disabled={!Helper.isNotNullAndUndefined(appDetail?.passwordPolicy)}
                >
                  {t('button.submit')}
                </Button>
                
              </form>
            }
            {
              pwdResetStatus === 2 &&
              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography variant='body1' style={{marginBottom: 30}}>
                  {t('label.pwdresetsuccess.message')}
                </Typography>
                <Button variant={'contained'} color={'primary'} 
                  onClick={_ => {
                    navigate('/');
                  }}
                >
                  {t('button.login')}
                </Button>
              </div>
            }
          </Paper>
        </Slide>
      }
    </Layout>
  );
}

export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(PwdResetPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
